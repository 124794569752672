<template>
  <div class="TheNav">
    <router-link :to="{ name: 'todo-list' }" data-testid="TheNav__TodoList">
      Todo List
    </router-link> |
    <router-link :to="{ name: 'recycle-bin' }" data-testid="TheNav__RecycleBin">
      Recycle Bin
    </router-link>
    <span class="account-info">
      <span class="icon">
        <i class="fa fa-user" />
      </span>
      <a v-if="!userInfo.uid" @click="login">Login</a>
      <span v-else>已登录</span>
    </span>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheNav',
  data () {
    return {
    }
  },
  computed: {
    ...mapGetters([
      'userInfo'
    ])
  },
  mounted () {
  },
  methods: {
    login () {
      this.$store.dispatch('login')
    }
  }
}
</script>

<style lang="scss" scoped>
.TheNav {
  padding: $theme--spacing-l;
  .account-info {
    float: right;
    span {
      margin-right: 5px;
    }
  }
  a {
    font-weight: bold;
    color: $theme--color-secondary;

    &.router-link-exact-active {
      color: $theme--color-main;
    }
  }
}
</style>
