import Vue from 'vue'
import firebase from 'firebase/app'

const localUserInfo = Vue.ls.get('userInfo')

const user = {
  state: {
    userName: localUserInfo ? localUserInfo.userName : '',
    userId: localUserInfo ? localUserInfo.userId : '',
    userInfo: localUserInfo || {}
  },

  mutations: {
    setUserName (state, name) {
      state.userName = name
    },

    setUserId (state, id) {
      state.userId = id
    },

    setUserInfo (state, userInfo) {
      state.userInfo = userInfo
    }
  },

  actions: {
    login ({ commit }) {
      return firebase.auth().signInAnonymously()
        .then((userCredential) => {
          Vue.ls.set('userInfo', userCredential.user)
          commit('setUserInfo', userCredential.user)
          commit('setUserId', userCredential.user.uid)
          commit('setUserName', userCredential.user.displayName)
        })
        .catch((error) => {
          console.error(error)
        })
    }
  }
}

export default user
