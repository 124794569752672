// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app'
// If you enabled Analytics in your project, add the Firebase SDK for Analytics
import 'firebase/analytics'
// Add the Firebase products that you want to use
import 'firebase/auth'
import 'firebase/firestore'

const firebaseConfig = {
  apiKey: 'AIzaSyACMIR6Ks7GVlZr_tcz4JwRs7OmPknpcww',
  authDomain: 'i-todo-list.firebaseapp.com',
  databaseURL: 'https://i-todo-list-default-rtdb.firebaseio.com',
  projectId: 'i-todo-list',
  storageBucket: 'i-todo-list.appspot.com',
  messagingSenderId: '119491193917',
  appId: '1:119491193917:web:2377aeffba3a223ce20e54',
  measurementId: 'G-5MRSDR734N'
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
